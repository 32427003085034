import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./IssuesList.css";

const IssuesList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedType, setSelectedType] = useState("all"); // change this
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  //const dataFetchedRef = useRef(false);
  const prevDataRef = useRef(null);
  const moment = require("moment");

  useEffect(() => {
    //if (dataFetchedRef.current) return;
    setLoading(true);
    console.log("Fetching data...");
    const fetchData = async () => {
      try {
        const newData = await fetchWithRetry("issuesList.json");
        if (JSON.stringify(newData) !== JSON.stringify(prevDataRef.current)) {
          setData(newData);
          prevDataRef.current = newData;
        }
        //dataFetchedRef.current = true;
        setLoading(false);
        setError(null);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(
          "There was an error fetching the data. Please try again by refreshing the page."
        );
        setLoading(false);
      }
    };
    fetchData();

    const query = new URLSearchParams(location.search);
    const type = query.get("type");
    if (type) {
      setSelectedType(type);
    } else {
      navigate("?type=all", { replace: true });
    }
  }, [location.search, navigate]);

  const handleChange = (event) => {
    const type = event.target.value;
    setSelectedType(type);
    navigate(`?type=${type}`);
  };

  const renderPageContent = () => {
    const format = "DD/MM/yyyy";
    const filteredData = data
      .filter(
        (item) => selectedType === "all" || item.Category === selectedType
      )
      .sort((a, b) => {
        if (a.PriorityLevel !== b.PriorityLevel) {
          return a.PriorityLevel - b.PriorityLevel; // Ascending order for priority
        }
        const bUDate = moment(b.LastUpdated, format).isValid()
          ? moment(b.LastUpdated, format).toDate()
          : null;
        const aUDate = moment(a.LastUpdated, format).isValid()
          ? moment(a.LastUpdated, format).toDate()
          : null;
        const bRDate = moment(b.ReportedDate, format).toDate();
        const aRDate = moment(a.ReportedDate, format).toDate();
        const bDate = bUDate ? bUDate : bRDate;
        const aDate = aUDate ? aUDate : aRDate;
        //console.log(`${b.ItemKeyId} ${bDate}; ${a.ItemKeyId} ${aDate}`);
        return bDate - aDate; // Descending order for ReportedDate
      });
    return (
      <div>
        {filteredData.map((item) => (
          <div key={item.ID} className="card p-1 w-100">
            <span className="text p-1 fw-bold">
              {item.ItemKeyId}: {item.Overview}
            </span>
            <div className="text p-1">Date Reported: {item.ReportedDate}</div>
            <p
              className="text p-1"
              dangerouslySetInnerHTML={{
                __html: (item.Description || "").replace(/\n/g, "<br/>"),
              }}
            ></p>
            <div>
              {item.image1 && (
                <div className="text-center">
                  <img
                    className="img-fluid p-1"
                    src={`images/${item.image1}`}
                    alt="Photo1"
                  />
                </div>
              )}
            </div>
            <div>
              {item.image2 && (
                <div className="text-center">
                  <img
                    className="img-fluid p-1"
                    src={`images/${item.image2}`}
                    alt="Photo2"
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const calculateDateDifference = (dateField) => {
    // Convert dateField from dd/mm/yyyy to a Moment object
    const inputDate = moment(dateField, "DD/MM/YYYY");

    // Get the current date
    const currentDate = moment();

    // Calculate the difference in days
    return currentDate.diff(inputDate, "days");
  };

  return (
    <div className="container">
      <h4 className="card-title fw-bold">List of Items</h4>
      <div className="small">
        This list does not include items in NHI inspection reports
      </div>
      <div className="p-1"></div>
      <div className="d-flex align-items-center p-1">
        <span className="me-2 text-nowrap p-1">Report type:</span>
        <select
          value={selectedType}
          onChange={handleChange}
          className="form-select"
        >
          <option value="all">All items</option>
          <option value="Brickwork">Brickwork</option>
          <option value="Electrical">Electrical</option>
          <option value="Plumbing">Plumbing</option>
          <option value="Joinery">Joinery</option>
          <option value="Doors">Doors</option>
          <option value="Window">Windows</option>
          <option value="Painting">Painting</option>
          <option value="Structural">Structural</option>
          <option value="Tiling">Tiling</option>
          {/* <option value="Roofing">Roofing</option> */}
        </select>
      </div>
      <div>Report generation date: 26/9/2024</div>
      <div className="my-1"></div>
      {loading ? (
        <div>Loading, please wait...</div>
      ) : error ? (
        <div className="p-3 alert alert-danger" role="alert">
          {error}
        </div>
      ) : (
        <div className="report">{renderPageContent()}</div>
      )}
    </div>
  );
};

const fetchWithRetry = async (
  url,
  options = {},
  retries = 3,
  backoff = 100
) => {
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error("Request failed");
    }
    // Attempt to parse the response as JSON
    try {
      const data = await response.json();
      return data;
    } catch (jsonError) {
      throw new Error("Response is not valid JSON");
    }
  } catch (error) {
    if (retries > 1) {
      // Wait for a specified backoff time before retrying
      await new Promise((resolve) => setTimeout(resolve, backoff));
      console.log(`Retrying fetch # ${retries} left...`);
      return fetchWithRetry(url, options, retries - 1, backoff * 2); // Exponential backoff
    } else if (retries === 1) {
      console.log("Retries exhausted. Forcing page reload.");
      window.location.reload(true); // Force a page reload from the server
    } else {
      throw error;
    }
  }
};

export default IssuesList;
